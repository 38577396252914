<template>
  <div id="solicitante">
    <big-title-color variant-class="dark">
      <template v-slot:text>Mis <span>movimientos</span>.</template>.
    </big-title-color>

    <vx-card class="mt-base" title="¿Cuáles han sido tus últimas actividades en la Red?">
       <p>En este apartado podrás consultar todos los movimientos realizados en tu cuenta relacionados a tus pagos.</p>
    </vx-card>

    <vx-card v-if="isMounted && !failed" class="mt-base">
        <div class="vx-row">
            <div class="vx-col sm:w-1/5 w-full mb-4">
                <label class="vs-input--label">Fecha de inicio</label>
                <datepicker
                    placeholder="Fecha inicio"
                    :language="es"
                    v-model="startDate"
                    format="dd/MM/yyyy"
                    :disabledDates="disabledDates"
                    />
            </div>
            <div class="vx-col sm:w-1/5 w-full mb-4">
                <label class="vs-input--label">Fecha de término:</label>
                <datepicker
                    placeholder="Fecha final"
                    :language="es"
                    v-model="endDate"
                    format="dd/MM/yyyy"
                    :disabledDates="disabledDates"
                    />
            </div>
            <div class="vx-col sm:w-3/5 w-full mb-4 flex">
                <vs-button @click="datesChanged" color="primary" style="height: 40px" class="mt-6 mr-1 px-3">Consultar</vs-button>
                <vs-button
                  color="primary"
                  icon-pack="feather"
                  type="border"
                  class="px-3 mt-6 ml-4"
                  icon="icon-download"
                  style="height: 40px"
                  @click="downloadCSV">Descargar movimientos</vs-button>
            </div>
        </div>
        <vs-table class="text-center" :sst="true" @sort="handleSort" ref="table" stripe :max-items="30" :data="groupedMovements" noDataText="No hay datos disponibles">
            <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
                <!-- ITEMS PER PAGE -->
                <div class="p-3 mb-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                  <span class="mr-2">Mostrando {{ from }} - {{ to }} de {{ totalRecords }}</span>
                </div>
            </div>

            <template slot="thead">
              <vs-th class="bold text-center" v-for="(col, idx) in columns" v-bind:key="idx">{{col.title}}</vs-th>
            </template>

            <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
                <vs-td v-if="showAtLeastOneMulti && !tr.multiple"></vs-td>
                <vs-td class="text-left">{{ dateTimeFormat(tr.created_at) }}</vs-td>
                <vs-td class="text-left">{{ tr.concept }}</vs-td>
                <vs-td><strong>{{ costFormat(tr.income) }}</strong></vs-td>
                <vs-td><strong>{{ costFormat(tr.outcome) }}</strong></vs-td>
                <vs-td><strong>{{ costFormat(tr.final_balance) }}</strong></vs-td>
                <template v-if="tr.multiple" slot="expand">
                  <vs-table class="w-full text-center" :data="movements(tr.reference)" noDataText="No hay registros disponibles">
                    <template slot-scope="{data}">
                      <vs-tr class="bold" :key="indextr" v-for="(trx, indextr) in data">
                        <vs-td></vs-td>
                        <vs-td class="text-left">{{ dateTimeFormat(trx.created_at) }}</vs-td>
                        <vs-td class="text-left">{{ trx.concept }}</vs-td>
                        <vs-td><strong>{{ costFormat(trx.income) }}</strong></vs-td>
                        <vs-td><strong>{{ costFormat(trx.outcome) }}</strong></vs-td>
                        <vs-td align="center"><strong>{{ costFormat(trx.final_balance) }}</strong></vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </template>
            </vs-tr>
            </template>
        </vs-table>
        <br>
        <vs-pagination :total="totalPages" v-model="currentx"></vs-pagination>
    </vx-card>
  </div>
</template>

<script>
import formatHelper from '@mixins/formatHelper';
import dateHelper from '@mixins/dateHelper';
import Datepicker from 'vuejs-datepicker';
import { es } from "vuejs-datepicker/dist/locale";

const columnDefinitions = [
  { title: "FECHA", key: "date", sortable: true },
  { title: "CONCEPTO", key: "concept", sortable: true },
  //{ title: "REFERENCIA", key: "reference", sortable: true },
  { title: "INGRESO", key: "income", sortable: true },
  { title: "EGRESO", key: "outcome", sortable: true },
  { title: "SALDO", key: "balance", sortable: true }
];

export default {
  name: "ApplicantMovements",
  mixins: [formatHelper, dateHelper],
  components: {
      Datepicker
  },
  async mounted() {
    this.setInitialDates();
    await this.loadMovements(1);
  },
  data() {
    return {
      isFirstTime: true,
      es: es,
      allData: [],
      orderKey: "",
      startDate: null,
      endDate: null,
      columns: columnDefinitions,
      isMounted: false,
      loading: false,
      failed: false,
      disabledDates: {
        from: new Date()
      },
      currentx: 1,
      initialDateRange: 30,
      maxRangeDays: 365,
      isInvalidRange: false,
      rangeError: "El rango de tiempo máximo para consulta o descarga es de 1 año.",
      groupedMovements: [],
      showAtLeastOneMulti: false,
    };
  },
  computed: {
    allMovements(){
      return this.allData.data || [];
    },
    from(){
      return this.allData.from;
    },
    to(){
      return this.allData.to;
    },
    totalRecords(){
      return this.allData.total;
    },
    totalPages(){
      return Math.ceil(this.allData.total / this.allData.per_page);
    }
  },
  watch: {
    loading: function(){
      if(this.loading) {
        this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    },
    currentx: async function (newv, oldv){
      await this.pager(newv);
    }
  },
  methods: {
    setInitialDates(){
      let sdate = new Date();
      sdate.setDate(sdate.getDate() - this.initialDateRange);
      this.startDate = sdate;
      this.endDate = new Date();
    },
    async loadMovements(page){
      // se genera log de accion
      if (this.isFirstTime) {
        this.saveApplicantLogAction("LC11-24");
        this.isFirstTime = false;
      }
      this.isMounted = false;
      this.loading = true;
      this.failed = false;
      try {
        let data = {
          start: this.startDate,
          end: this.endDate,
        }
        let url = `/api/v1/applicant/${this.ApplicantId}/movementsList?page=${page}`;
        let response = await axios.post(url, data);
        this.allData = response.data;
        this.groupMovements();
      } catch (e) {
        this.failed = true;
      }
      this.isMounted = true;
      this.loading = false;
    },
    async datesChanged() {
      const valid = this.validateDates();
      if(valid){
        await this.loadMovements(1);
        this.currentx = 1;
      }
    },
    async restoreRecords() {
        await this.loadMovements(1);
        this.currentx = 1;
    },
    handleSort(key, active) {
      console.log(`the user ordered: ${key} ${active}`)
    },
    async pager(page){
      await this.loadMovements(page);
    },
    async downloadCSV(){
      const valid = this.validateDates();
      if(!valid){
        return;
      }
      try {
        const startYear = this.startDate.getFullYear();
        const startMonth = this.startDate.getMonth() + 1;
        const startDay = this.startDate.getDate();
        const endYear = this.endDate.getFullYear();
        const endMonth = this.endDate.getMonth() + 1;
        const endDay = this.endDate.getDate();
        let params = `startYear=${startYear}&startMonth=${startMonth}&startDay=${startDay}&endYear=${endYear}&endMonth=${endMonth}&endDay=${endDay}`;
        let url = `${this.ApiDomain}/api/v1/applicant/${this.ApplicantId}/movementsListCSV?access_token=${this.AccessToken}&${params}`;
        window.open(url, "_blank");
        // se genera log de accion
        this.saveApplicantLogAction("LC11-25");
      }
      catch (e) {
        this.warn(e)
      }
    },
    validateDates(){
      this.isInvalidRange = false;
      if(this.startDate > this.endDate){
        this.isInvalidRange = true;
        this.errorNotifDialog("Aviso", "La fecha de inicio no puede ser mayor a la fecha de término.")
        return false;
      }
      const diffTime = Math.abs(this.startDate - this.endDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if(diffDays > this.maxRangeDays){
        this.isInvalidRange = true;
        this.errorNotifDialog("Aviso", this.rangeError);
        return false;
      }
      return true;
    },
    downloadCSVLocal(){
      let array = [];
      array.push(['Fecha', 'Concepto', 'Referencia', 'Ingreso', 'Egreso', 'Saldo']);
      this.allMovements.forEach(f => {
        array.push([f.created_at, f.concept, f.reference, f.income, f.outcome, f.final_balance]);
      });

      let str = '';
      for (let i = 0; i < array.length; i++) {
        let line = '';
        line = array[i].join(",");
        str += line + '\r\n';
      }
      let blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });

      let link = document.createElement('a');
      let url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'movimientos.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    movements(reference) {
        return this.allMovements
            .filter(mov => mov.reference === reference)
            .map(mov => mov);
    },
    movementsAmounts(reference) {
      let data = {
        count:0,
        incomes:0,
        outcomes:0,
        final_balance: 0
      };
      var finalBalanceSet=false;
      this.allMovements.filter(mov => mov.reference === reference).forEach(movement => {
        data.count +=1;
        if (movement.type == "deposit") {
          data.incomes += movement.amount;
        }else{
          data.outcomes += movement.amount;
        }
        if (!finalBalanceSet) {
          data.final_balance = movement.final_balance;
          finalBalanceSet = true;
        }
      });
      return data;
    },
    groupMovements(){
      this.showAtLeastOneMulti = false;
      this.groupedMovements = [];
      let groups = [];
      let group = {};
      this.allMovements.forEach(movement => {
          let nn = movement.trx_type.substr(1, 1);
          movement.date = new Date(movement.created_at);
          if(nn != "1"){
              movement.type = "charge";
              movement.outcome = movement.amount;
          }
          else {
              movement.type = "deposit";
              movement.income = movement.amount;
          }
          if (movement.reference == "" || movement.reference == null) {
            // let reference = movement.id;
            // movement.reference = reference;
            group = this.createGroup(false,movement.concept,null,movement.created_at,movement.income, movement.outcome,movement.final_balance);
            groups.push(group);
          }
          else{
            if (groups.filter(g => g.reference === movement.reference).length == 0) {
              var dataGroup = this.movementsAmounts(movement.reference);
              let multi = dataGroup.count > 1 ? true : false; 
              if (multi) {
                this.showAtLeastOneMulti = true;
                group = this.createGroup(multi,"Pago del proyecto "+this.getProjectAlias(movement.project_id)+" (ID: "+movement.project_id+")",movement.reference,movement.created_at,dataGroup.incomes,dataGroup.outcomes,dataGroup.final_balance);
              }else{
                let reference = movement.id;
                movement.reference = reference;
                group = this.createGroup(multi,movement.concept,reference,movement.created_at,movement.income,movement.outcome,movement.final_balance);
              }
              groups.push(group);
            }
          }
          this.groupedMovements = groups; 
      });
    },
    createGroup(multiple,concept,reference,created_at,income,outcome,final_balance){
      let group = {
        multiple: multiple,
        concept: concept,
        reference: reference,
        created_at: created_at,
        income: income,
        outcome: outcome,
        final_balance: final_balance,
      };
      return group;
    },
    getProjectAlias(id){
      return this.allData.projects
            .filter(proj => proj.id === id)
            .map(proj => proj.alias);
    }
  },
};
</script>